// Import relevant parts of Bootstrap.
// Customization: https://getbootstrap.com/docs/5.2/customize/sass/#importing

// Options: https://getbootstrap.com/docs/5.2/customize/options/
//$enable-negative-margins: true;

// Basic Bootstrap functions.
@import "bootstrap/scss/functions";

// Default variable overrides.
// Full list of default variables and maps: see 'bootstrap/scss/_variables.scss'

// Color palette inspired from: https://www.canva.com/colors/color-palettes/summer-cocktails/
$blue: #65d4ee;
$green: #9de89c;
$yellow: #fae1a1;
$yellow-punchy: #fac12f;
$cyan: #cfe9ef;

$primary: #2f6394;

$border-radius: 0;
$border-radius-sm: 0;

// Default Bootstrap variables.
@import "bootstrap/scss/variables";

$factual: #336534;
$legal: #8d6603;
$formal: $red;  // TODO: Set proper color.

// Map overrides.
// Ref: https://getbootstrap.com/docs/5.2/customize/sass/#maps-and-loops

$gaius-colors: (
  "accent1": $blue,
  "accent2": $cyan,
);
$theme-colors: map-merge($theme-colors, $gaius-colors);

// Required Bootstrap parts.
@import "bootstrap/scss/maps";
@import "bootstrap/scss/mixins";
@import "bootstrap/scss/root";

// Optional Bootstrap parts.
@import "bootstrap/scss/utilities";
@import "bootstrap/scss/reboot";
@import "bootstrap/scss/type";
// @import "bootstrap/scss/images";
@import "bootstrap/scss/containers";
@import "bootstrap/scss/grid";
@import "bootstrap/scss/helpers";

// Generate Bootstrap classes based on maps.
@import "bootstrap/scss/utilities/api";

// Selected Bootstrap components. CAUTION: Update '../js/common.js' accordingly.
// @import "bootstrap/scss/tables";
@import "bootstrap/scss/forms";
@import "bootstrap/scss/buttons";
@import "bootstrap/scss/transitions";
@import "bootstrap/scss/dropdown";
// @import "bootstrap/scss/button-group";
@import "bootstrap/scss/nav";
@import "bootstrap/scss/navbar"; // Requires nav
@import "bootstrap/scss/card";
// @import "bootstrap/scss/accordion";
// @import "bootstrap/scss/breadcrumb";
@import "bootstrap/scss/pagination";
// @import "bootstrap/scss/badge";
// @import "bootstrap/scss/alert";
// @import "bootstrap/scss/progress";
// @import "bootstrap/scss/list-group";
@import "bootstrap/scss/close";
// @import "bootstrap/scss/toasts";
@import "bootstrap/scss/modal"; // Requires transitions
// @import "bootstrap/scss/tooltip";
// @import "bootstrap/scss/popover";
// @import "bootstrap/scss/carousel";
// @import "bootstrap/scss/spinners";
// @import "bootstrap/scss/offcanvas"; // Requires transitions
// @import "bootstrap/scss/placeholders";

// Glyphicons (tweaked from Bootstrap 3)
@import "glyphicons";

// Utilities.
.w-26 {
  width: 26% !important;
}
.w-74 {
  width: 74% !important;
}
.flex-basis-0 {
  flex-basis: 0;
}
.text-decoration-underline-hover {
  text-decoration: none;
  &:hover,
  &:focus {
    text-decoration: underline;
  }
}

// Link icons.
a[href^="http://"]::after,
a[href^="https://"]::after {
  @extend .glyphicon;
  content: "\e164";  // glyphicon-new-window.
  margin-left: .25em;
}
a[href^="http://gaius."]::after,
a[href^="https://gaius."]::after {
  display: none !important;  // Just in case we have absolute URLs somewhere.
}
a[href^="/documents/"]::before {
  @extend .glyphicon;
  content: "\e022";  // glyphicon-file.
}

// Other elements.
blockquote {
  padding: 1rem;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  :last-child {
    margin-bottom: 0;
  }
  background-color: $cyan;
}
.img-gaius {
  @include rfs(7.5rem, height);
}
.list-no-bullets {
  list-style-type: none;
}
.decision-back a,
.search-filters a,
.search-criteria a,
.search-result a,
.footer-nav a {
  text-decoration: none;
  &:hover,
  &:focus {
    text-decoration: underline;
  }
}

// Page elements.
.footer-main {
  margin-top: 8rem;
}
.footer-nav {
  text-align: right;
  li {
    &:first-child {
      margin-top: 0 !important;
    }
  }
  a.active {
    text-decoration: underline;
  }
}
.footer-fragment2 {
  :last-child {
    margin-bottom: 0;
  }
  a {
    @extend .link-light;
  }
}
.toc {
  background-color: $yellow;
  top: 1rem;
}
.toc-link {
  text-decoration: none;
  &:hover,
  &:focus {
    text-decoration: underline;
  }
}
.page2col {
  margin-top: 2rem;
  h2, h3, h4 {
    text-align: center;
  }
}

// Main navbar.
#main-nav {
  .nav-link {
    padding-left: 1rem;
    padding-right: 0;
    margin-top: .5rem;
    color: darken($white, 26%);
    @media (min-width: map-get($grid-breakpoints, 'md')) {
      padding-left: 0;
      padding-bottom: 1rem;
      margin-right: 1.5rem;
    }
    &:last-child {
      @media (min-width: map-get($grid-breakpoints, 'md')) {
        margin-right: 0;
      }
    }
    &.active {
      color: $white;
      padding-left: .75rem;
      border-left: .25rem solid $yellow-punchy;
      @media (min-width: map-get($grid-breakpoints, 'md')) {
        padding-left: 0;
        padding-bottom: .75rem;
        border-left: 0;
        border-bottom: .25rem solid $yellow-punchy;
      }
    }
    &:hover,
    &:focus {
      color: $white;
    }
  }
}

// Search elements.
.search-start {
  .search-criteria {
    max-width: 50rem;
  }
  .search-heading {
    text-align: center;
  }
}
.cookies-message,
.task-description,
.task-response,
.search-description {
  :last-child {
    margin-bottom: 0;
  }
}

// Facets.
.palette-factual {
  &.card {
    border-color: #d6e9c6;
    background-color: #f7fbf5;
  }
  .card-header {
    color: $factual;
    background-color: #dff0d8;
    border-bottom-color: #d6e9c6;
  }
  h3 {
    color: $factual;
  }
}
.palette-legal {
  &.card {
    border-color: lighten($legal, 54%);
    background-color: lighten($legal, 70%);
  }
  .card-header {
    color: $legal;
    background-color: lighten($legal, 62%);
    border-bottom-color: lighten($legal, 54%);
  }
  h3 {
    color: $legal;
  }
}
.facet-flat {
  span {
    &::before {
      @extend .glyphicon;
      content: "\e080 ";  // glyphicon-chevron-right.
      font-size: .85em;
      margin-right: .45em;
      top: 1px;
    }
    &:first-child {
      &::before {
        content: "";
        margin-right: 0;
      }
    }
  }
}

// Decisions.
.decision-container {
  height: calc(100vh - 20rem);
}

// Cloak element until Alpine is fully loaded.
// https://alpinejs.dev/directives/cloak
[x-cloak] { display: none !important; }
